import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import contexts
import TeamState from './context/team/TeamState';
import ServiceState from './context/services/ServicesState';

// Import Components
import Navbar from './components/layout/navbar';
import Home from './components/home';
import Footer from './components/layout/footer';
import TeamMemberLiist from './components/team/teamList';
import Services from './components/services/serviceList';
import JobseekerForm from './components/jobs/JobseekerForm';

// Import CSS
import './css/App.css';

const App = () => {
  return (
    <TeamState>
      <ServiceState>
        <Router>
          <Navbar />
          <div className='main-content'>
            <Routes>
              <Route index element={<Home />} />
              <Route exact path='/team' element={<TeamMemberLiist />} />
              <Route exact path='/services' element={<Services />} />
              <Route exact path='/jobs' element={<JobseekerForm />} />
              <Route path='*' element={<Home />} />
            </Routes>
          </div>
          <Footer />
        </Router>
      </ServiceState>
    </TeamState>
  );
}

export default App;
