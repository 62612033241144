import React from 'react';

import '../../css/Services.css'; // Import the CSS file for styling

export const ServiceItem = ({ item }) => {
    return (
        <div className='service-item card'>
            <h2>{item.title}</h2>
            <br />
            <p>{item.description}</p>
            <ul>
                <li>
                    <span className='bold'>30 min: </span>
                    ${item.price30}
                </li>
                <li>
                    <span className='bold'>60 min: </span>
                    ${item.price60}
                </li>
                <li>
                    <span className='bold'>90 min: </span>
                    ${item.price90}
                </li>
                <li>
                    <span className='bold'>120 min: </span>
                    ${item.price120}
                </li>
            </ul>
        </div>
    );
};

export default ServiceItem;  // Ensure the component is exported correctly
